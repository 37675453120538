.pricing {
  max-width: 900px;
  margin: 50px auto;
  padding: 40px;
  background-color: #fdfdfd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.pricing h2 {
  font-size: 2.5rem;
  color: #ff5a5f;
  margin-bottom: 40px;
}

.plans {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.plan {
  flex: 1;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan h3 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

.plan p {
  font-size: 1.2rem;
  color: #666;
}

.plan button {
  background-color: #ff69b4;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.plan button:hover {
  background-color: #ff5a5f;
}

.plan:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.plan.basic {
  border-color: #ccc;
}

.plan.premium {
  border-color: #ffd700;
}

.plan.pro {
  border-color: #32cd32;
}

.plan .selected {
  background-color: #ff5a5f;
}

@media (max-width: 768px) {
  .plans {
    flex-direction: column;
    gap: 30px;
  }
}
