.height-estimator {
  max-width: 700px;
  margin: 50px auto;
  padding: 40px;
  background-color: #fdfdfd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.height-estimator h2 {
  font-size: 2rem;
  color: #ff5a5f;
  margin-bottom: 30px;
}

.height-estimator p {
  font-size: 1.2rem;
  color: #333;
}

.upload-area {
  border: 2px dashed #ff5a5f;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-area:hover {
  background-color: #ffe5e9;
}

.upload-area input {
  display: none;
}

.upload-area p {
  color: #333;
  font-size: 1.1rem;
  margin-top: 20px;
}

.image-preview {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 30px;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.error {
  color: red;
  margin-bottom: 20px;
}

button {
  background-color: #ff5a5f;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #e14d4f;
}

.estimation-result {
  margin-top: 30px;
}

.estimation-result h3 {
  color: #ff5a5f;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.estimation-result p {
  color: #333;
  font-size: 1.2rem;
}

.estimation-result .highlight {
  color: #ff5a5f;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
  display: block;
}

.estimation-result .range {
  font-size: 1.1rem;
  color: #666;
}
