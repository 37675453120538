.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f7f8fc;
  }
  
  .login-container h2 {
    font-size: 2rem;
    color: #ff5a5f;
    margin-bottom: 20px;
  }
  
  .form {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .input-field {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .input-field:focus {
    border-color: #ff69b4;
    outline: none;
    box-shadow: 0 0 10px rgba(255, 105, 180, 0.2);
  }
  
  .login-button {
    width: 100%;
    background-color: #ff69b4;
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #ff5a5f;
  }
  
  .error-message {
    color: #ff4444;
    margin-bottom: 15px;
  }
  
  .login-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  @media (max-width: 480px) {
    .form {
      width: 100%;
      padding: 20px;
    }
  }
  