.dashboard {
  max-width: 700px;
  margin: 50px auto;
  padding: 40px;
  background-color: #fdfdfd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.dashboard h1 {
  font-size: 2rem;
  color: #ff5a5f;
  margin-bottom: 30px;
}

.dashboard-content {
  margin-top: 20px;
}

.user-info {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  text-align: left;
}

.user-info p {
  color: #333;
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.upgrade-message {
  background-color: #ffeded;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.upgrade-message p {
  color: #ff5a5f;
  font-size: 1rem;
}

button {
  background-color: #ff5a5f;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #e14d4f;
}

.upgrade-button {
  background-color: #008CBA;
  margin-bottom: 20px;
}

.upgrade-button:hover:enabled {
  background-color: #006f9a;
}

.estimate-button {
  margin-top: 20px;
}
