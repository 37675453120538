.home {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  background-color: #fdfdfd;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.home h1 {
  font-size: 2.5rem;
  color: #ff5a5f;
  margin-bottom: 20px;
}

.home p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 40px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.cta-button {
  display: inline-block;
  background-color: #ff5a5f;
  color: white;
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e14d4f;
}

.features {
  margin-top: 50px;
  text-align: left;
}

.features h2 {
  font-size: 2rem;
  color: #ff5a5f;
  margin-bottom: 20px;
}

.features ul {
  list-style-type: none;
  padding-left: 0;
}

.features li {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;
}

.features li::before {
  content: '✔';
  color: #ff5a5f;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.5rem;
}
